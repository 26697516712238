import React, {useRef} from 'react'
import Header from '../components/Header/Header'
import Layout from '../components/Layout/Layout'
import Productos from '../components/Productos/Productos'
import Agiles from '../components/Agiles/Agiles'
import Procesos from '../components/Procesos/Procesos'
import Contacto from '../components/Contacto/Contacto'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) 

const Home = () => {

    const formRef = useRef(null)
    const executeScroll = () => scrollToRef(formRef)

    return (
        
        <>
            <Header executeScroll={executeScroll}/>
            <Layout>
                <Productos />
                <Agiles />
                <Procesos executeScroll={executeScroll} />
                <Contacto formRef={formRef} />
            </Layout>
        </>
    )
}

export default Home;

import React, { useState, Fragment } from 'react';
import styles from './Contacto.module.scss';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Modal from '../UI/Modal/Modal';

const Contacto = ({ formRef }) => {
	const [ isOpen, setIsOpen ] = useState(false);

	const contactSchema = Yup.object().shape({
		name: Yup.string().required(),

		lastName: Yup.string().required(),

		email: Yup.string().email().required(),

		message: Yup.string().required()
	});

	const SendForm = (values, reset) => {
		const url = 'https://api.mailing.paisanoscreando.com/public/send_mail';
		let { name, lastName, email, message } = values;
		const { resetForm } = reset;
		const formData = new FormData();

		formData.append('clientName', 'PaisanosCreando');
		formData.append('senderName', name + ' ' + lastName);
		formData.append('senderEmail', email);
		formData.append('message', `Nombre: ${name} ${lastName} <br/>Mail: ${email} <br/>Mensaje: ${message}`);
		formData.append('senderPhone', '-');
		formData.append('receiverEmail', 'info@paisanoscreando.com');
		formData.append('subject', 'Contacto desde la landing PaisanosCreando');

		axios
			.post(url, formData)
			.then((res) => {
				setIsOpen(true);
				setTimeout(() => {
					window.gtag_report_conversion('https://desarrollo.paisanoscreando.com/');
					console.log('gtag inicio');
				}, 2000);
			})
			.catch((err) => {
				console.log('Hubo un error');
			});
		resetForm({
			name: '',
			lastName: '',
			email: '',
			message: ''
		});
	};

	return (
		<section className={styles.formSection}>
			<Formik
				initialValues={{
					name: '',
					lastName: '',
					email: '',
					message: ''
				}}
				validationSchema={contactSchema}
				onSubmit={(values, { resetForm }) => SendForm(values, { resetForm })}
			>
				{({ handleSubmit, isValid }) => (
					<Fragment>
						<form ref={formRef} id='form' className={styles.content} onSubmit={handleSubmit}>
							<h3>¡Pongamonos en contacto!</h3>
							<h4>Llena tus datos y te contactaremos</h4>
							<div style={{ width: '100%', marginTop: '69px' }}>
								<div className={styles.formGroup}>
									<label htmlFor='name'>Nombre*</label>
									<Field type='text' name='name' id='name' />
								</div>
								<div className={styles.formGroup}>
									<label htmlFor='lastName'>Apellido*</label>
									<Field type='text' name='lastName' id='lastName' />
								</div>
								<div className={styles.formGroup}>
									<label htmlFor='email'>Email*</label>
									<Field type='email' name='email' id='email' />
								</div>
								<div className={styles.formGroup}>
									<label htmlFor='message'>Mensaje*</label>
									<Field type='textarea' name='message' id='message' component='textarea' />
								</div>

								<button type='submit' className={styles.btn} disabled={!isValid}>
									Contactame
								</button>
							</div>
						</form>
					</Fragment>
				)}
			</Formik>
			<Modal opened={isOpen} close={() => setIsOpen(false)}>
				<h4 style={{ fontSize: '3rem' }}>¡Gracias por contactarte!</h4>
				<p style={{ fontSize: '1.4rem' }}>Nos contactaremos a la brevedad</p>
				<button className={styles.btn} onClick={() => setIsOpen(false)}>
					Aceptar
				</button>
			</Modal>
		</section>
	);
};

export default Contacto;

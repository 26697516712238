import React from 'react';
import styles from './Header.module.scss';
import {ReactComponent as Logo} from '../../assets/images/paisanos-io.svg'
import {ReactComponent as F} from '../../assets/images/icon-fb.svg'
import {ReactComponent as I} from '../../assets/images/icon-instagram.svg'
import {ReactComponent as T} from '../../assets/images/twitter-logo-button.svg'
import {ReactComponent as LogoWhite} from '../../assets/images/logo-white.svg'


const Header = ({executeScroll}) => {
    return (
        <header className={styles.header}>
            <div className={styles.socialsContainer}>
                <div className={styles.socials}>
                    <div className={styles.logo}>
                        <Logo />
                    </div>
                    <div className={styles.contactItems}>
                        <div className={styles.phones}>
                            <a href="tel:+541134549876" target="_blank" rel="noopener noreferrer">(+54) 11 3454 9876</a>
                            <a href="mailto:info@paisanoscreando.com" target="_blank" rel="noopener noreferrer">info@paisanoscreando.com</a>
                        </div>
                        <div className={styles.media}>
                        <a href="https://www.facebook.com/PaisanosCreando/" target="_blank" rel="noopener noreferrer"><F/></a>
                        <a href="https://www.instagram.com/paisanos.creando/" target="_blank" rel="noopener noreferrer"><I/></a>
                        <a href="https://twitter.com/paisanoscreando/" target="_blank" rel="noopener noreferrer"><T/></a>
                        </div>
                    </div>
                </div>
            </div>
            <aside className={styles.aside}>
                <div className={styles.textContainer}>
                    <picture className={styles.logo}>
                        <LogoWhite />
                    </picture>
                    <h1>¡Creemos en las Buenas Ideas!</h1>
                    <h3>Como estudio digital tenemos la capacidad de asesorarte, para que llegues a lograr un  cambio hacia un mundo centrado en experiencias digitales significativas.</h3>
                    <button type="button" onClick={executeScroll}>Empecemos :)</button>
                </div>
            </aside>
        </header>
    )
}

export default Header

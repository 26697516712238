import React from 'react';
import styles from './AgileItem.module.scss'; 

const AgileItem = ({img, title, description}) => {
    return (
        <div className={styles.itemContainer}>
            <div className={styles.imgContainer}>
                <img src={img} alt=""/>
            </div>
            <div className={styles.textContainer}>
                {title} <span>{description}</span>
            </div>
        </div>
    )
}

export default AgileItem;

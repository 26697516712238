import React from 'react';
import styles from './Agiles.module.scss';
import AgileItem from './AgileItem/AgileItem';
import validar from '../../assets/images/validar.svg'
import pensando from '../../assets/images/pensando.svg'
import crecer from '../../assets/images/crecer.svg'
const Agiles = () => {
    return (
        <section className={styles.container}>
            <div className={styles.contenido}>
                <h2>¡Somos Ágiles!</h2>
                <div className={styles.features}>
                    <AgileItem 
                    img={validar} 
                    title="Validamos tu idea creando un MVP" 
                    description="Planificamos un lanzamiento rápido y flexible para evolucionar a partir de las verdaderas necesidades de los usuarios." />
                    <AgileItem 
                    img={pensando} 
                    title="Diseñamos pensando en el usuario :)" 
                    description="Analizamos nuestro público y diseñamos nuestro producto basandonos en los datos recolectados en la investigacion UX." />
                    <AgileItem 
                    img={crecer} 
                    title="Te acompañamos en cada etapa del proceso." 
                    description="Creamos los cimientos para poder hacer crecer  un proyecto cuidando el presupuesto y mejorando los tiempos de producción." />
                </div>
            </div>
        </section>
    )
}

export default Agiles;

import React from 'react'
import styles from './ProcesosItem.module.scss'


const ProcesosItem = ({title, description, image}) => {

    
    return (
        <div className={styles.content}>
            <div className={styles.info}>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
            <div className={styles.image}>
                <img src={image} alt={title}/>
            </div>
        </div>
    )
}

export default ProcesosItem

import React from 'react';
import styles from './Procesos.module.scss';
import ProcesosItem from './ProcesosItem/ProcesosItem';
import product from '../../assets/images/productdesign.svg'
import uiux from '../../assets/images/uiux.svg'
import desarolloevolutivo from '../../assets/images/desarolloevolutivo.svg'
import {ReactComponent as Line} from '../../assets/images/line-home.svg'
import {ReactComponent as LineMobile} from '../../assets/images/line-mobile.svg'


const Procesos = ({executeScroll}) => {

    return (
        <>
        <section className={styles.backgroundMobile} style={{width: '100%', position:'relative'}}>
            <div className={styles.lineDesktop}>
            <Line />
            </div>
            <div className={styles.lineMobile}>
            <LineMobile />
            </div>
            <div className={styles.section}>
                <div className={styles.container}>
                    <h2>Te contamos <span>nuestros procesos</span> en simples pasos...</h2>
                </div>
            </div>
            <div className={styles.sectionItems}>
                <div className={styles.container}>
                    <ProcesosItem title="Product & Design Thinking" description="No solo un enfoque sino una mentalidad para descubrir la próxima gran herramienta." image={product}/>
                    <ProcesosItem title="UI & UX Design" description="Diseños pensados a partir de la interacción del usuario y las nuevas tendencias del mercado." image={uiux}/>
                    <ProcesosItem title="Desarrollo Evolutivo" description="Desarrollamos asegurándonos mantener la calidad planificada y el objetivo del producto." image={desarolloevolutivo}/>
                </div>
            </div>
            <div className={styles.lastItem}>
                <h4>Lanzar</h4>
                <h4>Medir</h4>
                <h4>Aprender</h4>
            </div>

            <button className={styles.btn} onClick={executeScroll}>¡Queremos escuchar tu idea!</button>
        </section>
        
        </>
    )
}

export default Procesos;

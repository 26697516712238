import React from 'react'
import styles from './Productos.module.scss';
const Productos = () => {
    return (
        <section className={styles.productos}>
            <div className={styles.textContainer}>
                <h2>Creamos productos únicos que transforman tu negocio.</h2>
                <h3>Nos centramos en el comportamiento del usuario para diseñar cada producto siguiendo las últimas tendencias en tecnología y metodologías de trabajo.</h3>
            </div>
        </section>
    )
}

export default Productos;
